<template>
  <v-app class="public-wrapper">
    <div
      v-if="loading"
      class="loader"
    >
      <div class="logo">
        <img
          src="@/assets/images/logo-circle.svg"
          height="35"
          style="background: #2b2f44; border-radius: 50%;"
        >
      </div>
      <div class="mt-3 purple--text text--lighten-5">
        {{ loadingText }}
      </div>
    </div>
    <router-view />
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['loading', 'loadingText']),
    },
  }
</script>

<style lang="scss">
  .public-wrapper {
    .v-application--wrap {
      .order-price {
        max-width: 1400px;
        width: 100%;
      }
    }

    .loader {
      z-index: 99999;

      background-color: #242939cc;
      display: flex;
      flex-direction: column;
    }
  }
</style>
